import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import ShowFilters from '../../../components/care/reports/filters/show_filters';
import ShowContent from '../../../components/care/reports/show_content';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { Grid, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { isGradeInSelectedRanges } from '../../../components/care/reports/filters/utils';
import CareNav from '../../../components/care/shared/nav';
import { hideClassGroupingData, getFiltersMap } from '../../../components/care/shared/utils';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

const Container = (props) => {
  const { survey } = props;
  const observation_id = props.observation_id ? Number(props.observation_id) : null;
  const observable_id = props.observable_id ? Number(props.observable_id) : null;

  const completedObservations = survey.observations.filter((o) => o.status === 'completed');
  const initialFilteredObservations = observation_id ? survey.observations.filter((o) => o.id === observation_id) : completedObservations;
  const [filteredObservations, setFilteredObservations] = useState(initialFilteredObservations);

  const [selectedSchools, setSelectedSchools] = useState(new Set());
  const [selectedObservables, setSelectedObservables] = useState(new Set());
  const [selectedObservers, setSelectedObservers] = useState(new Set());
  const [selectedGradeLevels, setSelectedGradeLevels] = useState(new Set());
  const [selectedGradeRanges, setSelectedGradeRanges] = useState(new Set());
  const [selectedContentAreas, setSelectedContentAreas] = useState(new Set());
  const [selectedInstructionLanguages, setSelectedInstructionLanguages] = useState(new Set());
  const [selectedDiversityOptions, setSelectedDiversityOptions] = useState(new Set());
  const [selectedDateRange, setSelectedDateRange] = useState('all');
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [initialGroupBy, setInitialGroupBy] = useState('default');

  const hideClassRoomEngagement = hideClassGroupingData(survey);
  const filtersMap = getFiltersMap([survey], completedObservations);

  const filterObservationsByDate = (observation) => {
    if (selectedDateRange === 'all' || !observation?.observation_date) {
      return true;
    }

    const observationDate = dayjs(observation.observation_date).startOf('day');
    const today = dayjs().startOf('day');

    switch (selectedDateRange) {
      case 'last_7_days':
        return today.diff(observationDate, 'day') <= 7;
      case 'last_30_days':
        return today.diff(observationDate, 'day') <= 30;
      case 'custom': {
        const [start, end] = customDateRange;
        const startDate = start ? dayjs(start).startOf('day') : null;
        const endDate = end ? dayjs(end).startOf('day') : null;
        return startDate && endDate ? observationDate.unix() >= startDate.unix() && observationDate.unix() <= endDate.unix() : true;
      }
      default:
        return true;
    }
  };

  /**
   * Filter observations based on selected schools and observables
   */
  useEffect(() => {
    if (
      selectedSchools.size > 0 ||
      selectedObservables.size > 0 ||
      selectedObservers.size > 0 ||
      selectedGradeLevels.size > 0 ||
      selectedGradeRanges.size > 0 ||
      selectedContentAreas.size > 0 ||
      selectedInstructionLanguages.size > 0 ||
      selectedDiversityOptions.size > 0 ||
      selectedDateRange !== 'all'
    ) {
      const newFilteredObservations = completedObservations.filter((o) => {
        return (
          (selectedSchools.size === 0 || selectedSchools.has(o.school_name?.id)) &&
          (selectedObservables.size === 0 || selectedObservables.has(o.observable?.id)) &&
          (selectedObservers.size === 0 || selectedObservers.has(o.observer?.id)) &&
          (selectedGradeLevels.size === 0 || selectedGradeLevels.has(o.grade_level)) &&
          (selectedGradeRanges.size === 0 || isGradeInSelectedRanges(o.grade_level, selectedGradeRanges)) &&
          (selectedContentAreas.size === 0 || selectedContentAreas.has(o.content_area?.id)) &&
          (selectedInstructionLanguages.size === 0 || selectedInstructionLanguages.has(o.instruction_language?.id)) &&
          (selectedDiversityOptions.size === 0 || selectedDiversityOptions.has(o.diverse_classroom)) &&
          filterObservationsByDate(o)
        );
      });
      setFilteredObservations(newFilteredObservations);
      setIsFilterActive(true);
    } else {
      setFilteredObservations(completedObservations);
      setIsFilterActive(false);
    }
  }, [
    selectedSchools,
    selectedObservables,
    selectedObservers,
    selectedGradeLevels,
    selectedGradeRanges,
    selectedContentAreas,
    selectedInstructionLanguages,
    selectedDiversityOptions,
    selectedDateRange,
    customDateRange,
  ]);

  const handleDateRangeFilterChange = (range, customRange = null) => {
    if (range === 'custom' && customRange) {
      setSelectedDateRange('custom');
      setCustomDateRange(customRange);
    } else {
      setSelectedDateRange(range);
      setCustomDateRange([null, null]);
    }
  };

  const handleFilterChange = (setSelected, idOrIds) => {
    // Ensure always an array
    const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];

    ids.forEach((id) => {
      setSelected((prevSelected) => {
        const newSelected = new Set(prevSelected);
        newSelected.has(id) ? newSelected.delete(id) : newSelected.add(id);
        return newSelected;
      });
    });
  };

  const clearAllFilters = () => {
    setSelectedSchools(new Set());
    setSelectedObservables(new Set());
    setSelectedObservers(new Set());
    setSelectedGradeLevels(new Set());
    setSelectedGradeRanges(new Set());
    setSelectedContentAreas(new Set());
    setSelectedInstructionLanguages(new Set());
    setSelectedDiversityOptions(new Set());
    setSelectedDateRange('all');
    setCustomDateRange([null, null]);
  };

  const clearFilters = (filterName) => {
    if (!filterName) {
      clearAllFilters();
      return;
    }
    switch (filterName) {
      case 'school_name':
        setSelectedSchools(new Set());
        break;
      case 'observable':
        setSelectedObservables(new Set());
        break;
      case 'observer':
        setSelectedObservers(new Set());
        break;
      case 'grade_level':
        setSelectedGradeLevels(new Set());
        break;
      case 'grade_range':
        setSelectedGradeRanges(new Set());
        break;
      case 'content_area':
        setSelectedContentAreas(new Set());
        break;
      case 'instruction_language':
        setSelectedInstructionLanguages(new Set());
        break;
      case 'diverse_classroom':
        setSelectedDiversityOptions(new Set());
        break;
      case 'date_range':
        setSelectedDateRange('all');
        setCustomDateRange([null, null]);
        break;
    }
  };

  useEffect(() => {
    if (observable_id) {
      handleFilterChange(setSelectedObservables, observable_id);
      setInitialGroupBy('observable');
    }
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CareNav {...props} back_url={props.base_url} />
        <div className="mt-2 care-reporting">
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <h3>{survey.name}</h3>
            <IconButton aria-label="Print" size="small" onClick={() => window.print()} className="no-print">
              <FontAwesomeIcon icon={faPrint} color="#585858" />
            </IconButton>
          </Stack>
          <Grid container columnSpacing={{ xs: 4 }} wrap="wrap" marginTop={5}>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="no-print"
              sx={{
                position: { xs: 'relative', md: 'sticky' },
                top: { md: '100px' },
                height: '100%',
              }}
            >
              <ShowFilters
                onSchoolFilterChange={(idOrIds) => handleFilterChange(setSelectedSchools, idOrIds)}
                onObservableFilterChange={(idOrIds) => handleFilterChange(setSelectedObservables, idOrIds)}
                onObserverFilterChange={(idOrIds) => handleFilterChange(setSelectedObservers, idOrIds)}
                onGradeLevelFilterChange={(idOrIds) => handleFilterChange(setSelectedGradeLevels, idOrIds)}
                onGradeRangeFilterChange={(idOrIds) => handleFilterChange(setSelectedGradeRanges, idOrIds)}
                onContentAreaFilterChange={(idOrIds) => handleFilterChange(setSelectedContentAreas, idOrIds)}
                onInstructionLanguageFilterChange={(idOrIds) => handleFilterChange(setSelectedInstructionLanguages, idOrIds)}
                onDiversityOptionFilterChange={(idOrIds) => handleFilterChange(setSelectedDiversityOptions, idOrIds)}
                onDateRangeFilterChange={handleDateRangeFilterChange}
                selectedSchools={selectedSchools}
                selectedObservables={selectedObservables}
                selectedObservers={selectedObservers}
                selectedGradeLevels={selectedGradeLevels}
                selectedGradeRanges={selectedGradeRanges}
                selectedContentAreas={selectedContentAreas}
                selectedInstructionLanguages={selectedInstructionLanguages}
                selectedDiversityOptions={selectedDiversityOptions}
                selectedDateRange={selectedDateRange}
                surveys={[survey]}
                observations={completedObservations}
                isFilterActive={isFilterActive}
                onClearFilters={clearFilters}
                hideClassRoomEngagement={hideClassRoomEngagement}
                filtersMap={filtersMap}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <ShowContent
                survey={survey}
                observations={filteredObservations}
                baseUrl={props.base_url}
                initialGroupBy={initialGroupBy}
                leads={props.leads}
                recipients={props.recipients}
                hideClassRoomEngagement={hideClassRoomEngagement}
                filtersMap={filtersMap}
              />
            </Grid>
          </Grid>
        </div>
      </LocalizationProvider>
    </>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  survey: PropTypes.object.isRequired,
  care_tool_admin: PropTypes.bool,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
