import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OpenCloseButton from './open_close_button';
import EditHistory from './edit_history';
import Status from './status';
import Actions from './actions';
import AdmNodeSelector from './adm_node_selector';
import Button from '@mui/material/Button';
import ActionLink from './action_link';
import { faSquarePlus, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObservationsTable from '../observations/table';
import algoliasearch from 'algoliasearch/lite';
import Attachments from './attachments';
import { difference } from 'lodash';
import { trackCzEvent } from '../../../helpers/track_cz_event';

export default ({ ...props }) => {
  const isCreatingObservation = props.isCreatingObservation || false;
  const [name, setName] = useState(props.survey ? props.survey.name : '');
  const [description, setDescription] = useState(props.survey ? props.survey.description : '');
  const [surveyTypeId, setSurveyTypeId] = useState(
    props.survey ? props.survey.survey_type_id : props.survey_type_category.survey_types[0] && props.survey_type_category.survey_types[0].id
  );
  const [errors, setErrors] = useState({});

  const allNodes = {
    aspect_categories: props.survey_type_category.adm_tree.map((aspectCategory) => aspectCategory.id),
    aspects: props.survey_type_category.adm_tree
      .map((aspectCategory) => aspectCategory.aspects)
      .flat()
      .map((aspect) => aspect.id)
      .filter((id) => !!id),
    summary_indicators: props.survey_type_category.adm_tree
      .map((aspectCategory) => aspectCategory.aspects.map((aspect) => aspect.summary_indicators))
      .flat()
      .flat()
      .map((summary_indicator) => summary_indicator.id)
      .filter((id) => !!id),
    indicators: props.survey_type_category.adm_tree
      .map((aspectCategory) =>
        aspectCategory.aspects.map((aspect) => aspect.summary_indicators.map((summaryIndicator) => summaryIndicator.indicators))
      )
      .flat()
      .flat()
      .flat()
      .map((indicator) => indicator.id)
      .filter((id) => !!id),
  };

  const defaultSelected = () => {
    const defaultSelected = {
      aspect_categories: [],
      aspects: [],
      summary_indicators: [],
      indicators: [],
    };
    props.survey_type_category.adm_tree.forEach((aspectCategory) => {
      if (aspectCategory.survey_config.required || (props.survey && props.survey.aspect_categories.map((ac) => ac.id).includes(aspectCategory.id))) {
        defaultSelected['aspect_categories'].push(aspectCategory.id);
      }
      aspectCategory.aspects.forEach((aspect) => {
        if (aspect.survey_config.required || (props.survey && props.survey.aspects.map((a) => a.id).includes(aspect.id))) {
          defaultSelected['aspects'].push(aspect.id);
        }
        aspect.summary_indicators.forEach((summaryIndicator) => {
          if (
            summaryIndicator.survey_config.required ||
            (props.survey && props.survey.summary_indicators.map((si) => si.id).includes(summaryIndicator.id))
          ) {
            defaultSelected['summary_indicators'].push(summaryIndicator.id);
          }
          summaryIndicator.indicators.forEach((indicator) => {
            if (indicator.survey_config.required || (props.survey && props.survey.indicators.map((i) => i.id).includes(indicator.id))) {
              defaultSelected['indicators'].push(indicator.id);
            }
          });
        });
      });
    });
    return defaultSelected;
  };

  const [selectedAdmNodes, setSelectedAdmNodes] = useState(defaultSelected());

  const toggleAdmNode = (type, ids) => {
    setSelectedAdmNodes((prevSelectedAdmNodes) => {
      const newNodes = prevSelectedAdmNodes[type] ? [...prevSelectedAdmNodes[type]] : [];
      ids = Array.isArray(ids) ? ids : [ids];

      ids.forEach((id) => {
        const index = newNodes.indexOf(id);
        if (index === -1) {
          newNodes.push(id);
        } else {
          newNodes.splice(index, 1);
        }
      });
      return { ...prevSelectedAdmNodes, [type]: newNodes };
    });

    props.onUnsavedChangesChanged(true);
  };

  const checkAll = () => {
    setSelectedAdmNodes(allNodes);
  };

  const uncheckAll = () => {
    setSelectedAdmNodes({
      aspect_categories: [],
      aspects: [],
      summary_indicators: [],
      indicators: [],
    });
  };

  const searchClient = useMemo(() => algoliasearch(props.algoliaAppId, props.algoliaSearchKey), [props.algoliaAppId, props.algoliaSearchKey]);

  const { show_adm_numbers } = props.survey_type_category;

  const isAllChecked = () => {
    return (
      difference(allNodes.aspect_categories, selectedAdmNodes.aspect_categories).length == 0 &&
      difference(allNodes.aspects, selectedAdmNodes.aspects).length == 0 &&
      difference(allNodes.summary_indicators, selectedAdmNodes.summary_indicators).length == 0 &&
      difference(allNodes.indicators, selectedAdmNodes.indicators).length == 0
    );
  };

  const isDelegated = props.survey ? props.survey['delegated?'] : false;

  return (
    <>
      <Grid container columnSpacing={8} marginTop={8} wrap="nowrap" sx={{ height: '100%' }}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={errors['name']}
                fullWidth
                value={name}
                label={`${props.survey_type_category.name} Name`}
                variant="standard"
                inputProps={{ style: { fontSize: 31 } }}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                  props.onUnsavedChangesChanged(true);
                }}
                helperText={errors['name']?.join(', ')}
                data-cy="input-survey-name"
              />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              <TextField
                fullWidth
                value={description || ''}
                label="Description"
                variant="standard"
                multiline
                onChange={(e) => {
                  setDescription(e.currentTarget.value);
                  props.onUnsavedChangesChanged(true);
                }}
                data-cy="input-survey-description"
              />
            </Grid>
            <Grid item xs={4} marginTop={2}>
              <FormControl variant="standard" fullWidth required>
                <InputLabel id="observation-type-select-label">Observation Type</InputLabel>
                <Select
                  labelId="observation-type-select-label"
                  label="Observation Type"
                  value={surveyTypeId}
                  onChange={(e) => {
                    setSurveyTypeId(e.target.value);
                    props.onUnsavedChangesChanged(true);
                  }}
                >
                  {props.survey_type_category.survey_types.map((st, i) => (
                    <MenuItem value={st.id} key={st.id}>
                      {st.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} marginTop={4}>
              <AdmNodeSelector
                selectedNodes={selectedAdmNodes}
                admTree={props.survey_type_category.adm_tree}
                isCareDetail={props?.survey_type_category?.name?.toLowerCase() === 'care detail'}
                showAdmNumbers={show_adm_numbers}
                onChange={toggleAdmNode}
                checkAll={checkAll}
                uncheckAll={uncheckAll}
                isAllChecked={isAllChecked()}
              />
            </Grid>
            <Grid item xs={12} marginTop={8}>
              <ObservationsTable
                searchClient={searchClient}
                survey={props.survey}
                activeObservations={props.activeObservations}
                onEditObservation={props.onEditObservation}
                onDeleteObservation={props.onDeleteObservation}
                key={props.observations_key}
                emptyMessage="This template has no observations."
                {...props}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            minWidth: '300px',
            height: '100%',
            position: 'sticky',
            top: '100px',
          }}
        >
          <div>
            <OpenCloseButton
              onClick={(status) => {
                props.onUnsavedChangesChanged(false);
                props
                  .onSave(
                    {
                      name,
                      description,
                      survey_type_id: surveyTypeId,
                      surveys_adm_nodes: selectedAdmNodes,
                      archived_at: status === 'enabled' ? null : props.survey.archived_at,
                      status: status,
                    },
                    'enabling'
                  )
                  .catch((response) => setErrors(response.errors));
              }}
              {...props}
            />
          </div>
          <div className="mt-3">
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="secondary"
              sx={{ minWidth: '150px' }}
              onClick={(e) => {
                props.onUnsavedChangesChanged(false);
                props
                  .onSave({
                    name,
                    description,
                    survey_type_id: surveyTypeId,
                    surveys_adm_nodes: selectedAdmNodes,
                  })
                  .catch((response) => setErrors(response.errors));
              }}
              data-cy="button-save-survey"
            >
              Save
            </Button>
          </div>
          {props.survey && <Actions isDelegated={isDelegated} {...props} />}
          <hr />
          {props.survey && props.survey.status === 'enabled' && (
            <>
              {!isDelegated && (
                <ActionLink
                  icon={faSquarePlus}
                  onClick={() => props.onCreateObservation()}
                  text="Create Observation"
                  isLoading={isCreatingObservation}
                />
              )}
              {props.survey && (
                <div>
                  <a
                    className="link-dark-no-underline d-inline-flex"
                    href={`/care/surveys/${props.survey.id}/export_to_xlsx.xlsx`}
                    onClick={() => {
                      trackCzEvent('CARE:DownloadData', `User downloaded data for template ${props.survey.id}`, 1);
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FontAwesomeIcon icon={faDownload} color="#585858" />
                      <span>Export Data</span>
                    </Stack>
                  </a>
                </div>
              )}
              <hr />
              <EditHistory {...props} />
              <hr />
              <Attachments {...props} />
              <hr />
              <Status {...props} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
