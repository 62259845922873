import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';
import AdmNodeDonutChart from '../adm_node_donut_charts';
import { grades } from '../../shared/utils';
import Notes from './notes';
import { isHqimAnalysisIncluded } from '../../shared/utils';

const headCells = [
  {
    id: 'observer',
    numeric: false,
    disablePadding: true,
    label: 'Observer',
  },
  {
    id: 'school',
    numeric: true,
    disablePadding: false,
    label: 'School',
  },
  {
    id: 'grade',
    numeric: true,
    disablePadding: false,
    label: 'Grade',
  },
  {
    id: 'observable',
    numeric: true,
    disablePadding: false,
    label: 'Teacher',
  },
  {
    id: 'response',
    numeric: true,
    disablePadding: false,
    label: 'Response',
  },
];

const ChartDrawerContents = ({ admNode, observations, setObservation, surveys, showSummaryOnly }) => {
  const [orderBy, setOrderBy] = React.useState('observer');
  const [order, setOrder] = React.useState('asc');
  const [sortedObservations, setSortedObservations] = React.useState(observations);
  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  const showHqim = surveys.every(isHqimAnalysisIncluded);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setSortedObservations(
      sortedObservations.slice().sort((a, b) => {
        let value;
        let aResponse = a.responses.find((r) => r.adm_node_id === admNode.id && r.adm_node_type === admNode.type).value;
        let bResponse = b.responses.find((r) => r.adm_node_id === admNode.id && r.adm_node_type === admNode.type).value;

        switch (orderBy) {
          case 'observer':
            if (order === 'asc') {
              value = a.observer?.full_name?.localeCompare(b.observer?.full_name);
            } else {
              value = b.observer?.full_name?.localeCompare(a.observer?.full_name);
            }
            break;
          case 'school':
            if (order === 'asc') {
              value = a.school_name?.name?.localeCompare(b.school_name?.name);
            } else {
              value = b.school_name?.name?.localeCompare(a.school_name?.name);
            }
            break;
          case 'observable':
            if (order === 'asc') {
              value = a.observable?.name?.localeCompare(b.observable?.name);
            } else {
              value = b.observable?.name?.localeCompare(a.observable?.name);
            }
            break;
          case 'response':
            if (order === 'asc') {
              value = aResponse?.localeCompare(bResponse);
            } else {
              value = bResponse?.localeCompare(aResponse);
            }
            break;
          case 'grade':
            if (order === 'asc') {
              value = grades.indexOf(a.grade_level) - grades.indexOf(b.grade_level);
            } else {
              value = grades.indexOf(b.grade_level) - grades.indexOf(a.grade_level);
            }
            break;
        }
        return value;
      })
    );
  }, [order, orderBy]);

  const cellStyles = {
    padding: '10px 16px',
    cursor: 'pointer',
  };

  return (
    <>
      <AdmNodeDonutChart admNode={admNode} observations={observations} showHqimCharts={showHqim} showSummaryOnly={showSummaryOnly} />
      <Typography sx={{ marginTop: 5 }} variant="h6">
        Observations
      </Typography>
      {sortedObservations.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedObservations.map((o) => (
              <TableRow key={o.id} onClick={(e) => setObservation(o)}>
                <TableCell sx={{ ...cellStyles, paddingLeft: '0' }}>{o.observer.full_name}</TableCell>
                <TableCell sx={cellStyles}>{o.school_name.name}</TableCell>
                <TableCell sx={cellStyles}>{o.grade_level}</TableCell>
                <TableCell sx={cellStyles}>{o.observable?.name}</TableCell>
                <TableCell sx={cellStyles}>
                  {o.responses.find((r) => r.adm_node_id === admNode.id && r.adm_node_type === admNode.type).value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {sortedObservations.length === 0 && <Typography variant="body1">There are no observations with yes/no responses for this node.</Typography>}
      <Notes admNode={admNode} observations={observations} />
    </>
  );
};

ChartDrawerContents.propTypes = {
  observations: PropTypes.array.isRequired,
  admNode: PropTypes.object,
};

export default ChartDrawerContents;
